header {
  margin-bottom: 2em;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid #ccc;
  font-size: 0.6rem;
  line-height: 0.8rem;
}

.navbar__left,
.navbar__right {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.navbar__left {
  display: none;
}

.navbar__right {
  width: 100%;
  justify-content: stretch;
}

.navbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 auto;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  padding: 0.75rem 0.5rem;
  border-left: 1px solid #ccc;
  border-right: 1px solid #fff;
  text-decoration: none;
}

.navbar-item:hover {
  text-decoration: none;
}

.navbar__new {
  border-left: 0;
}

.navbar-item i.icon {
  display: none;
}

.title-bar__desktop-logo {
  display: none;
}

@media only screen and (max-width: 767px) {
  .navbar .ui.blue.button {
    margin: 0;
    font-size: 0.6rem;
    padding: 0.6875em 1em;
    width: 100%;
    background: transparent;
    box-shadow: none;
    color: #335075;
    border-radius: 0;
  }

  .navbar .ui.blue.button:active,
  .navbar .ui.blue.button:hover,
  .navbar .ui.blue.button:focus {
    background: transparent;
    box-shadow: none;
    color: #335075;
  }

  .navbar__logout {
    padding: 0;
    align-items: stretch;
  }
}

@media only screen and (min-width: 768px) {
  .navbar {
    border-bottom: 0;
    font-size: 16px;
    line-height: 1.4285em;
  }

  .navbar__left {
    display: inline-block;
    flex: 0 1 auto;
    width: auto;
    margin-left: 6.25vw;
    text-align: left;
  }

  .navbar__right {
    width: auto;
    flex: 1 1 auto;
    margin-right: 6.25vw;
    justify-content: flex-end;
  }

  .navbar-item {
    width: auto;
    flex: unset;
    background: transparent;
    padding: 1rem;
    border: 0;
    text-align: left;
  }

  .navbar-item span {
    text-decoration: underline;
  }

  .navbar-item i.icon {
    flex: 0 0 auto;
    display: inline-block;
    height: 1.125em;
  }

  .navbar .ui.button {
    font-size: 1rem;
    padding: 0.6875em 1.5em;
  }

  .title-bar__desktop-logo {
    display: inline-block;
    padding: 1rem;
    box-sizing: content-box;
  }
}
