.pin-bubble {
  text-align: center;
  padding: 32px;
  margin-bottom: 24px;
  border-radius: 8px;
}

.pin-bubble h2 {
  font-size: 2.4em;
  margin-bottom: 0;
}

.pin-bubble--green {
  background-color: #e2efdd;
  border: 4px solid #83a970;
}

.pin-bubble--red {
  background-color: #efdcdc;
  border: 4px solid #a96d6e;
}
