body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  border-top: 26px #337496 solid;
}

@media only screen and (min-width: 768px) {
  header {
    padding-top: 26px;
  }
}

header h1 {
  color: #337496;
  font-size: 2.6em;
}

body > .grid {
  height: 80%;
}

.wizard .column {
  max-width: 550px;
}

.ui.form {
  margin-top: 20px;
}
.ui.form .field {
  margin-bottom: 20px !important;
}
.ui.form .field > label {
  text-align: left !important;
  margin-bottom: 10px !important;
}

.ui.form .input-feedback {
  background-color: #fff6f6;
  color: #9f3a38;
  -webkit-box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent;
}
