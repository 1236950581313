.react-file-reader-input {
  display: none;
}

.csv-confirm__text-area textarea {
  white-space: nowrap;
  width: 100%;
  font-family: monospace;
}

i.large.icon {
  text-decoration: none;
}
