.PinLookup {
  margin-top: 1rem;
}

.PinLookup__hint {
  margin-top: 2rem;
}

.PinLookup__report {
  background: rgb(238, 243, 247);
  border: 1px solid rgb(93, 104, 118);
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;
  color: #333;
}

.PinLookup__report--highlighted {
  display: inline-block;
  padding: 0.05em 0;
  background: rgb(255, 193, 7);
}

.PinLookup__report .ui.divider {
  margin: 0.5rem 0 1rem;
}

.PinLookup__results {
  text-align: start;
}

.PinLookup__pin {
  color: rgba(41, 83, 118, 0.95);
}
