.PinTool {
  margin-top: 1rem;
}

.PinTool__report {
  background: rgb(243, 243, 243);
  border: 1px solid #333;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;
  color: #333;
}

.PinTool__report h2 {
  font-size: 1.2rem;
  border-bottom: 1px solid #333;
}

.PinTool__log {
  background: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding: 1rem;
  text-align: left;
}

.PinTool__hint {
  margin-top: 2rem;
}
