.AffiliateTool {
  margin-top: 1rem;
}

.AffiliateTool__report {
  background: rgb(243, 243, 243);
  border: 1px solid #333;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;
  text-align: left;
  color: #333;
}

.AffiliateTool__report h2 {
  font-size: 1.2rem;
  margin-bottom: 6px;
}

.AffiliateTool__report h5 {
  margin-top: 0;
  font-weight: 400;
  border-bottom: 1px solid #333;
}

.AffiliateTool__log {
  background: #fff;
  border: 1px solid #333;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding: 1rem;
  text-align: left;
}

.AffiliateTool__hint {
  margin-top: 2rem;
}
