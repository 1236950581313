.status-log {
  color: #888;
}

.comment p {
  margin-bottom: 0.2em;
}

.status-log__date,
.comment__date {
  color: #888;
  font-size: 80%;
}

.comment-list__loader {
  margin-top: 3em !important;
}
